import * as React from "react";
import styles from "./control.module.css";
import FormControlContainer from "../form-control-container";

const Control = React.forwardRef(function Control(
  {
    label,
    id,
    fullWidth,
    name,
    value,
    onChange,
    required,
    editable = true,
    endIcon,
    initialValue,
    type,
    multiline,
    rows = 4,
    cols,
    step,
    pattern,
    smallFont,
    inputClassName,
    max,
  },
  ref,
) {
  function handleChange(e) {
    const value = e.target.value;
    return onChange(value, e);
  }

  return (
    <FormControlContainer fullWidth={fullWidth} id={id} label={label}>
      <span className={styles.icon}>{endIcon}</span>
      {multiline ? (
        <textarea
          id={id}
          name={name}
          value={value}
          onChange={onChange ? handleChange : undefined}
          required={required}
          disabled={!editable}
          defaultValue={initialValue}
          className={`${styles.input} ${styles.multiline}`}
          rows={rows}
          cols={cols}
          ref={ref}
        />
      ) : (
        <input
          id={id}
          name={name}
          value={value}
          onChange={onChange ? handleChange : undefined}
          className={`${styles.input} ${endIcon ? styles.endIcon : ""} ${
            smallFont ? styles.smallFont : ""
          } ${inputClassName}`}
          required={required}
          disabled={!editable}
          defaultValue={initialValue}
          type={type}
          step={step}
          pattern={pattern}
          ref={ref}
          max={max}
        />
      )}
    </FormControlContainer>
  );
});

export default Control;
