import BreadCrums from "../../../components/breadcrums";
import { formatPrice, generateGetLoader } from "../../../lib/helpers";
import { useFetcher, useLoaderData } from "react-router-dom";
import styles from "./customer-detail.module.css";
import * as React from "react";
import Modal from "../../../components/modal";
import CreditLimitModal from "./credit-limit-modal";
import { createPostRequest } from "../../../lib/network";

export async function loader({ params }) {
  const response = await generateGetLoader(
    `${process.env.REACT_APP_BASE_URL}/api/customer/admin/getOne/${params.id}`,
    "customer",
  )();
  return response;
}

export async function action({ request, params }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData.entries());
  if (data.action === "credit-limit-update") {
    const promises = [];
    const elem = document.getElementById("credit-limit-amount");
    const prevLimit = parseFloat(elem.getAttribute("data-limit"));
    const newLimit = parseFloat(data["limit"]);
    console.log(prevLimit, newLimit, prevLimit === newLimit);
    if (newLimit !== prevLimit) {
      promises.push(
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/customer/credit/admin/update`,
          { UpdateCustomerID: params.id, CreditLimitUpdateAmount: newLimit },
        ),
      );
    }
    const numPayment = parseFloat(data["payment"]);
    if (!isNaN(numPayment) && numPayment > 0) {
      promises.push(
        createPostRequest(
          `${process.env.REACT_APP_BASE_URL}/api/customer/credit/admin/refund`,
          { UpdateCustomerID: params.id, CreditLimitRefundAmount: numPayment },
        ),
      );
    }
    await Promise.all(promises);
  } else {
  }
  return null;
}

export default function CustomerDetail() {
  const { customer } = useLoaderData();
  const fetcher = useFetcher();
  const [limitUpdate, setLimitUpdate] = React.useState(false);

  return (
    <>
      <BreadCrums
        segments={[
          { label: "Customers", link: "../customers" },
          {
            label: `${
              customer.Customer_Business_Name || customer.Customer_Name
            }`,
            link: ".",
          },
        ]}
      />
      <div className={styles.topSection}>
        <div className={styles.customerDetail}>
          <div>
            <span>Name:</span>
            <span>
              {customer.Customer_Business_Name || customer.Customer_Name}
            </span>
          </div>
          <div>
            <span>Account Type:</span>
            <span>{customer.Customer_Type}</span>
          </div>
          <div>
            <span>Email:</span>
            <span>
              {customer.Customer_Business_Email || customer.Customer_Email}
            </span>
          </div>
          <div>
            <span>Name:</span>
            <span>
              {customer.Customer_Business_Phone || customer.Customer_Phone}
            </span>
          </div>
          <div>
            <div role="button" className={styles.topSectionButton}>
              Update Customer
            </div>
          </div>
        </div>
        <div className={`${styles.customerDetail} ${styles.limits}`}>
          <div>
            <span>Credit Limit Available:</span>
            <span>
              {formatPrice(customer.Credit_Limit_Available_Amount || 0)}
            </span>
          </div>
          <div>
            <span
              id="credit-limit-amount"
              data-limit={customer.Credit_Limit_Amount || 0}
            >
              Credit Limit Amount:
            </span>
            <span>{formatPrice(customer.Credit_Limit_Amount || 0)}</span>
          </div>
          <div className={styles.largeDiv}>
            <div
              role="button"
              className={styles.topSectionButton}
              onClick={() => setLimitUpdate(true)}
            >
              Update Limit
            </div>
          </div>
        </div>
      </div>
      <Modal open={limitUpdate}>
        <CreditLimitModal
          setLimitUpdate={setLimitUpdate}
          customer={customer}
          fetcher={fetcher}
        />
      </Modal>
    </>
  );
}
