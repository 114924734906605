import PrimaryButton from "../../../components/primary-button";
import Control from "../../../components/control";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import styles from "./customer-detail.module.css";
import { formatPrice } from "../../../lib/helpers";

export default function CreditLimitModal({
  setLimitUpdate,
  fetcher,
  customer,
}) {
  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    fetcher.submit(
      {
        action: "credit-limit-update",
        limit: formData.get("Credit_Limit_Amount"),
        payment: formData.get("Manual_Payment"),
      },
      { method: "POST" },
    );
    setLimitUpdate(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.modalHeader}>
        <span className={styles.modalHeading}>Update Credit Limit</span>
        <Close onClick={() => setLimitUpdate(false)} />
      </div>
      <div className={styles.modalContent}>
        <div className={styles.customerInfo}>
          <div>
            <span>Customer:</span>
            <span>
              {customer.Customer_Business_Name || customer.Customer_Name}
            </span>
          </div>
          <div>
            <span>Email:</span>
            <span>
              {customer.Customer_Business_Email || customer.Customer_Email}
            </span>
          </div>
          <div>
            <span>Credit Limit Available:</span>
            <span>
              {formatPrice(customer.Credit_Limit_Available_Amount || 0)}
            </span>
          </div>
        </div>
        <div className={styles.limitsContainer}>
          <Control
            name="Credit_Limit_Amount"
            id="credit-limit-amount"
            label="Credit Limit Amount"
            required
            initialValue={customer.Credit_Limit_Amount || 0}
            fullWidth
            type="number"
          />
          <div className={styles.manualPayment}>
            <Control
              name="Manual_Payment"
              id="manual-payment"
              label="Manual Credit Payment"
              fullWidth
              type="number"
              step={0.01}
              max={
                (customer.Credit_Limit_Amount || 0) -
                (customer.Credit_Limit_Available_Amount || 0)
              }
            />
          </div>
        </div>
        <div className={styles.modalButtonContainer}>
          <PrimaryButton
            label="Update CL"
            type="submit"
            loading={
              fetcher.state === "loading" || fetcher.state === "submitting"
            }
          />
        </div>
      </div>
    </form>
  );
}
